<template>
  <div>
    <div class="teamUserinfo mb-2">
      <div>
        <div class="d-flex justify-content-between">
          <h3 class="table-title-text"><text-shimmer></text-shimmer></h3>
          <div class="userCalender d-flex">
            <div class="">
              <b-input-group>
                <!-- <month-picker-input
                  :no-default="false"
                  @change="showDate()"
                ></month-picker-input> -->
                <span><text-shimmer class="mr-50"></text-shimmer></span>
              </b-input-group>
            </div>
            <div class="partTimebtn">
              <text-shimmer class="mb-2"></text-shimmer>              
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center ">
          <div>
            <skeleton-loader-vue
                type="circle"
                :width="100"
                :height="100"
                animation="wave"    
                class="mr-2"            
              /> 
          </div>
          <div class="teamuserName">
            <h4><text-shimmer></text-shimmer></h4>
            <div>             
              <span><text-shimmer></text-shimmer></span>
            </div>
          </div>
        </div>
        <div class="userDiscription">
          <ul class="m-0 p-0 d-flex">
            <li>
              <h2><text-shimmer></text-shimmer></h2>
              <span><text-shimmer></text-shimmer></span>
            </li>
            <li>
              <h2><text-shimmer></text-shimmer></h2>
              <span><text-shimmer></text-shimmer></span>
            </li>
            <li>
              <h2><text-shimmer></text-shimmer></h2>
              <div class="d-flex flex-wrap">
                <p>
                 <text-shimmer></text-shimmer>
                </p>
                <!-- <p><b-badge pill variant="light-success">Vue.js</b-badge></p> -->
              </div>
            </li>
            <li v-show="false">
              <h2><text-shimmer></text-shimmer></h2>
              <div class="d-flex flex-wrap">                
                <p>
                  <text-shimmer></text-shimmer>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoaderVue from "skeleton-loader-vue"
import TextShimmer from "./TextShimmer.vue"

export default {
    name: 'MyteamdtlShimmer',
    components:{
        SkeletonLoaderVue,
        TextShimmer
    }
}
</script>

<style>

</style>